import { useTranslate } from '@common/hooks'
import cx from 'classnames'
import Link from 'next/link'

import classNames from './styles.module.scss'


interface IProps {
  className?: string,
  link: string,
  tabIndex?: number,
}

RedirectСard.defaultProps = {
  className: '',
  tabIndex: undefined,
}

export default function RedirectСard({
  className,
  link,
  tabIndex,
}: IProps): JSX.Element | null {
  const { translate } = useTranslate()
  return (
    <Link href={`/apps/${link}`} legacyBehavior>
      <a className={cx(classNames.wrapper, className)} tabIndex={tabIndex} >
        <span className={classNames.link}>{translate('Show more')}</span>
      </a>
    </Link>
  )
}
