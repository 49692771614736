export default function handlePrepareSettings(settings: IApplication['settings']): Record<string, unknown> {
  const result = Object.keys(settings)
    .reduce((obj, key) => {
      const setting = settings[key] as IApplicationSettingOptions
      if(setting.required) {
        obj[key] = setting.default
      } else if(setting.default) {
        obj[key] = setting.default
      }
      if(obj[key]) {
        if(setting.type === 'boolean' && typeof obj[key] !== 'boolean') {
          obj[key] = false
        } else if(setting.type === 'number' && typeof obj[key] !== 'number') {
          obj[key] = Number(obj[key])
        } else if(setting.type === 'string' && typeof obj[key] !== 'string') {
          obj[key] = String(obj[key])
        }
      }
      return obj
    }, {} as Record<string, unknown>)
  return result
}
