import ModalFooter from '../../ModalFooter'
import classNames from './styles.module.scss'


interface IProps {
  onHide: () => void,
  installationError: string,
}

export default function OneClickErrorModal({
  installationError,
  onHide,
}: IProps): JSX.Element {
  return (
    <>
      <div className={classNames.wrapper}>
        <p className={classNames.errorMessage}>{installationError}</p>
      </div>
      <ModalFooter
        onClose={onHide}
        submitBtnText="OK"
      />
    </>
  )
}
