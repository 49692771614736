import type { IApiError } from '@apps/Application/widgets/AppSettingsForm'
import type { IInputs } from '@apps/Application/widgets/AppSettingsForm/appSettingsForm.types'
import submitHandler from '@apps/Application/widgets/AppSettingsForm/submitHandler'
import SlotSelector from '@apps/Application/widgets/SlotSelector'
import SettingsField from '@common/forms/SettingsField'
import { useAppDispatch, useErrorMessages, usePermissionText, useSlots, useTranslate } from '@common/hooks'
import { installAppFromListSync } from '@redux/slices/applicationList'
import cx from 'classnames'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import ModalFooter from '../../ModalFooter'
import classNames from './styles.module.scss'


interface IProps {
  app: IApplication,
  onHide: () => void,
  permissions: IPermissionKeyed[],
  settings: Record<string, unknown>,
  slot: number,
}

export default function OneClickConfirmationModal({
  app,
  onHide,
  permissions,
}: IProps): JSX.Element {
  const { translate } = useTranslate()
  const dispatch = useAppDispatch()
  const { resetSlot, slotForInstallation } = useSlots()
  const { getPermissionText } = usePermissionText()
  const [serverError, setServerError] = useState<IApiError | null>(null)
  const [slot, setSlot] = useState<number | undefined>(undefined)

  const methods = useForm<IInputs>({
    mode: 'onChange',
    defaultValues: permissions.reduce((obj, permission) => {
      obj[permission.key] = permission.value
      return obj
    }, {} as Record<string, unknown>),
  })

  const { handleSubmit, formState: { isSubmitting } } = methods

  const onClose = () => handleSubmit(async (fields) => {
    if(!slotForInstallation && slot === undefined) { return }
    return await submitHandler({
      fields: {
        ...fields,
      },
      appId: app.id,
      slot: slotForInstallation || slot as number,
      latestAppVersionId: app.latestVersion,
    }, { replaceSlot: Boolean(slot), submitAction: 'install-one-click', preventWindowClose: true }, {
      setError: (apiError: IApiError) => {
        setServerError(apiError)
      },
      clearErrors: () => {
        setServerError(null)
      },
      onSuccess: () => {
        resetSlot()
        onHide()
        void dispatch(installAppFromListSync({ id: app.id, slot: slotForInstallation || slot as number }))
      },
    })
  })()

  const { serverErrorMessage } = useErrorMessages(serverError)

  return (
    <FormProvider {...methods}>
      <div className={classNames.wrapper}>
        <form>
          {(!slotForInstallation) && (
            <div className={cx(classNames.slotSelectorWrapper, classNames.fieldRow)}>
              <div className={classNames.slotDescription}>
                <h4>{translate('Select slot')}</h4>
                <p>{translate('All slots are occupied. To add a new application, you need to replace an existing one.')}</p>
              </div>
              <SlotSelector
                slot={slot}
                onClick={(slot: number) => setSlot(slot)}
                className={classNames.slotSelector}
                disabled={false}
              />
          </div>
          )}
          {Boolean(permissions.length) && (
            <p className={classNames.message}>{translate('The app you are adding is requesting the following permissions')}</p>
          )}
          {permissions.map((permission, ind) => (
            <SettingsField
              type="permission"
              name={permission.key}
              key={permission.key}
              label={translate(permission.label)}
              description={getPermissionText(permission)}
              data-test={`${permission.key}-field`}
              tabIndex={300 + ind}
              layoutClassName={classNames.fieldRow}
              className={classNames.label}
            />
          ))}
            {Boolean(serverError) && (
              <p className={classNames.serverError}>
                {serverErrorMessage}
              </p>
            )}
        </form>
      </div>
      <ModalFooter
        isSubmitting={isSubmitting}
        onClose={onClose}
        onHide={onHide}
        submitDisabled={!slotForInstallation && slot === undefined}
      />
    </FormProvider>
  )
}
