import { useAppDispatch, useSlots, useTranslate } from '@common/hooks'
import { getAppSlug } from '@common/utils/uuid'
import { setPreviousListPage } from '@redux/slices/common'
import cx from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import type { Ref } from 'react'
import { useMemo } from 'react'

import ApplicationStatus from '../ApplicationStatus'
import handleHighlightSubstring from './handleHighlightSubstring'
import classNames from './styles.module.scss'


interface IProps {
  application: IApplication,
  name: string
  summary?: string
  author: string
  id: string
  className?: string,
  refProp?: (node: Element) => void,
  tabIndex?: number,
  dataTest: string,
  search?: string,
  isInstalled: boolean,
  isActive: boolean,
  children?: React.ReactNode,
  reason?: string,
}

AppCard.defaultProps = {
  isEmpty: false,
  className: '',
  refProp: undefined,
  summary: '',
  tabIndex: undefined,
  search: undefined,
  children: null,
  reason: undefined,
}

export default function AppCard({
  application,
  author,
  children,
  className,
  dataTest,
  id,
  name,
  refProp,
  search,
  summary,
  tabIndex,
}: IProps): JSX.Element | null {
  const { translate } = useTranslate()
  const dispatch = useAppDispatch()
  const appSlug = useMemo(() => {
    return getAppSlug(id, name)
  }, [id, name])

  const ref = refProp as Ref<HTMLAnchorElement> | undefined
  const router = useRouter()

  const handleSaveListLink = () => {
    dispatch(setPreviousListPage(router.asPath))
  }

  const {
    userSlots,
  } = useSlots()

  const updatedApp = useMemo<IApplication>(() => {
    if(userSlots && userSlots.length) {
      const app = userSlots.find(slot => slot.id === id)
      return {
        ...application,
        reason: app?.reason,
        isActive: app?.isActive || false,
        isInstalled: app?.isInstalled || false,
      }
    }
    return {
      ...application,
      isActive: false,
      isInstalled: false,
    }
  }, [userSlots, id, application])

  if(appSlug === null) {
    void router.push({
      pathname: '/404',
    })
    return null
  }

  return (
    <Link href={`/apps/${appSlug}/settings`} ref={ref} legacyBehavior>
      <a data-test={dataTest} className={cx(classNames.wrapper, className)} tabIndex={tabIndex} onClick={handleSaveListLink}>
        <header className={classNames.header}>
          <span className={classNames.title} data-test="app-name">
            {handleHighlightSubstring(name, search)}
          </span>
        </header>
        <p className={classNames.summary} data-test="app-summary">
          {handleHighlightSubstring(summary, search)}
        </p>
        <footer className={classNames.appFooter}>
          <span className={classNames.authorWrapper}>
            {translate('by')}
            <span className={classNames.author} data-test="app-author">
              {handleHighlightSubstring(author, search)}
            </span>
          </span>
          {children}
          <ApplicationStatus application={updatedApp}/>
        </footer>
      </a>
    </Link>
  )
}
