import cx from 'classnames'

import classNames from './styles.module.scss'


const handleHighlightSubstring = (str?: string, substr?: string): string | JSX.Element => {
  if(!substr || !str) { return String(str) }
  const sanitizedSubstr = substr.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  const parts = str.split(new RegExp(`(${sanitizedSubstr})`, 'gi'))
  return (
    <>
      {parts.map((part, i) => (
        <span
          key={i}
          className={cx(part.toLowerCase() === substr.toLowerCase() && classNames.searched)}
        >
          {part}
        </span>
      ))}
    </>
  )
}

export default handleHighlightSubstring
