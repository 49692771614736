import { useAppDispatch, useAppSelector, useMyApp, useSession, useSlots, useTranslate } from '@common/hooks'
import ModalWrapper from '@common/modals/ModalWrapper'
import getPermissions from '@common/utils/getPermissions'
import handlePrepareSettings from '@common/utils/handlePrepareSettings'
import { getAppSlug } from '@common/utils/uuid'
import Button from '@common/widgets/Button'
import { oneClickInstallAsync } from '@redux/slices/applicationList'
import type { IRejectedPayloadType } from '@redux/slices/applicationList/applicationList.types'
import { fetchUserSlots } from '@redux/slices/userSlots/userSlots'
import { useRouter } from 'next/router'
import type { MouseEvent } from 'react'
import { useEffect, useMemo, useState } from 'react'

import OneClickConfirmationModal from '../OneClickConfirmationModal'
import OneClickErrorModal from '../OneClickErrorModal'


interface IProps {
  app: IApplication,
  reducer: AppListReducer,
}

export default function OneClickButton({
  app,
}: IProps): JSX.Element {
  const [showPermissionModal, setShowPermissionModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [installationError, setInstallationError] = useState<string | null>(null)
  const { translate } = useTranslate()
  const dispatch = useAppDispatch()
  const router = useRouter()

  const userSlotsAreLoaded = useAppSelector(store => Boolean(store.userSlots.data))
  const {
    arrayOfSlot,
    isLoading: slotsLoading,
    resetSlot,
    slotForInstallation,
    slotId,
    userSlots,
  } = useSlots()
  const permissions = useMemo(() => getPermissions(app, userSlots) || [], [app, userSlots])
  const handlePreparedAppSettings = useMemo(() => handlePrepareSettings(app.settings), [app.settings])

  const { isMyApp, isMyAppInstalled } = useMyApp(app)
  const { isAuthorizedUser } = useSession()

  const handleTriggerOneClick = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    if(Object.values(handlePreparedAppSettings).some(setting => setting === undefined)) {
      return
    }
    e.stopPropagation()
    e.preventDefault()
    if(app.appLoading) { return }
    if(slotForInstallation) {
      setIsLoading(true)

      const permissionsValues = permissions.reduce((obj, permission) => {
        obj[permission.key] = true
        return obj
      }, {} as IPermissionValues)

      const req: IRequestAppInstallOneClick = {
        appId: app.id,
        slot: slotForInstallation,
        replaceSlot: false,
        permissions: permissionsValues,
        latestAppVersionId: app.latestVersion,
        replacePermissions: false,
      }

      void dispatch(oneClickInstallAsync(req))
        .then(({ payload, meta }) => {
          if(meta.requestStatus === 'rejected') {
            const errorPayload = payload as IRejectedPayloadType
            if(!Array.isArray(errorPayload?.detail) && (errorPayload?.detail?.type === 'AppLimitError' || errorPayload?.detail?.type === 'AppPermissionConflictsError')) {
              setShowPermissionModal(true)
              return
            } else if(!Array.isArray(errorPayload?.detail) && (errorPayload?.detail?.msg !== 'Application already installed.')) {
              setInstallationError(errorPayload?.detail?.msg || translate('Something went wrong. Please try again later.'))
              setShowErrorModal(true)
            } else {
              void router.push(`/apps/${getAppSlug(app.id, app.name) as string}/settings`)
            }
          }
          resetSlot()
          void dispatch(fetchUserSlots())
        })
        .catch(() => {
          void router.push(`/apps/${getAppSlug(app.id, app.name) as string}/settings`)
        })
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      setShowPermissionModal(true)
    }
  }

  useEffect(() => {
    let timer: number
    if(installationError && !showErrorModal && window) {
      timer = window.setTimeout(() => {
        setInstallationError(null)
      }, 500)
    }
    return () => {
      timer && window.clearTimeout(timer)
    }
  }, [showErrorModal, installationError])

  return (
    <>
      <ModalWrapper
        component={OneClickErrorModal}
        title={translate('Error During Installation')}
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
        size="sm"
        installationError={installationError}
      />
      <ModalWrapper
        component={OneClickConfirmationModal}
        title={translate('Update app permission')}
        show={showPermissionModal}
        onHide={() => {
          void dispatch(fetchUserSlots())
          setShowPermissionModal(false)
        }}
        size="sm"
        app={app}
        permissions={permissions}
        settings={handlePreparedAppSettings}
        slot={slotId || arrayOfSlot.findIndex(elem => elem === '') + 1}
      />
      {userSlotsAreLoaded && (
        (isMyAppInstalled || !isAuthorizedUser || isMyApp)
          ? null
          : (
            <Button
              variant="transparent-blue"
              width="m"
              onClick={handleTriggerOneClick}
              isLoading={slotsLoading || isLoading}
              data-test="one-click-installation-btn"
            >
              {translate('Add app')}
            </Button>
            )
      )}
    </>
  )
}
